.formio-form {
  @import "node_modules/formiojs/dist/formio.form.min.css";
}

.fa.fa-remove {
  @apply cursor-pointer before:content-["x"] text-red-400;
}

i

.body, .html {
  margin: 0;
  padding: 0;
}

.formio-component-file div.row {
  @apply flex justify-between;
}


.fileName .col-form-label .col-sm-10 {
  @apply text-red-400;
}

.form-text.error {
@apply overflow-hidden;
}

div.alert.alert-danger {
  @apply hidden;
  /* @apply bg-red-100 border border-red-400 text-red-700 mx-3 mb-2 px-4 py-3 rounded sticky top-2 left-0 z-20; */
}

.form-group.has-feedback.formio-component.has-error {
  @apply bg-red-100;
}

/* Hide elemnts after 6 to not fill screen with errors */
.alert.alert-danger ul li:nth-child(n+6) {
  @apply hidden;
}

.alert.alert-danger p {
  @apply text-gray-900 mb-2;
}

.formio-component input.form-control:disabled,
.formio-component textarea.form-control:disabled {  
   @apply cursor-not-allowed;
}

.formio-component {
  @apply text-gray-900 font-sans border-red-100 px-4 py-2 ;
}

.formio-component-form {
  @apply p-0
}

.card-header {
  @apply p-2
}

.form-control .ui .fluid .selection .dropdown {
  @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5;
}

.formio-component label {
  @apply text-sm font-medium;
}

.formio-component .formio-errors .error {
  @apply text-red-600;
}

/* div is here to increase specificity */
div .card-header.bg-default {
    @apply bg-gray-100 pl-2;
}

.card-header {
    @apply bg-gray-100;
}

.formio-component-tabs .card {
  @apply bg-white rounded-lg border shadow-md;
}

.formio-component-tabs .card-header {
  @apply border-0 p-0 pt-2;
}

.formio-component-tabs .card-body {
  @apply pb-0 px-3 sm:px-4;
}

.formio-component-tabs .card-header ul.nav-tabs {
  @apply mx-0 px-2 sm:px-4 border-b border-gray-200 shadow-md mt-2 overflow-y-auto lg:overflow-y-visible flex flex-nowrap scroll-hidden;
}

.formio-component-tabs .card-header ul.nav-tabs li {
  @apply px-2 pb-2 whitespace-nowrap text-base border-b border-transparent;
}

.formio-component-tabs .card-header ul.nav-tabs li a {
  @apply p-0 border-0 text-gray-600 font-normal;
}

.formio-component-tabs .card-header ul.nav-tabs li.active {
  @apply border-b border-gray-600;
}

.formio-component-tabs .card-header ul.nav-tabs li.active a {
  @apply font-semibold text-gray-800;
}

.formio-component input.form-control, .formio-component textarea.form-control {
  @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5;
}

div.choices.form-group.formio-choices {
  @apply border 
}

.formio-component-select .choices {
  @apply px-0 flex items-center w-full h-10 text-lg font-bold text-gray-900 bg-gray-50 rounded-lg;
}

.formio-component-select .choices .form-control {
  @apply bg-transparent;
} 

.formio-component-select .choices__list--dropdown {
  @apply font-normal absolute inset-x-0 z-10 py-1 text-base list-none bg-white rounded divide-y divide-gray-100 shadow hidden;
}

.formio-component-select .choices__list--dropdown.is-active {
  @apply block;
}

.formio-component-select .choices__list--dropdown .choices__list {
  @apply divide-y divide-gray-100;
}

.formio-component-select .choices__list--dropdown .choices__item {
  @apply block py-2 px-4 text-sm text-gray-700;
}

.formio-component-select .choices__list--dropdown .choices__item:hover {
  @apply bg-primary-50;
}

.formio-component-select .choices__item--selectable {
  @apply p-0;
}

.choices__item.choices__item--selectable {
  @apply px-3 text-sm font-light;
}

.formio-component-select .choices__list--single {
  @apply align-bottom;
}

.formio-component-select .formio-select-autocomplete-input {
  @apply p-0;
}

.formio-component-select .form-control.is-invalid {
  background-position: right calc(1.375em + 0.1875rem) center;
}

.formio-component-tags .choices__inner {
  @apply items-center py-0 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5;
}

.formio-component-tags .choices__item.choices__item--selectable {
  @apply mb-0;
}

.formio-component-tags .choices__input.choices__input--cloned {
  @apply mb-0;
}

.formio-component-tags input:focus {
  @apply ring-0;
}

.formio-component-tags .choices__item.choices__item--selectable {
  @apply px-2 py-1 rounded text-sm;
}

.formio-component-datetime .input-group {
  @apply flex-nowrap items-stretch;
}

.formio-component-datetime .input-group-append {
  @apply ml-[-10px] z-10;
}

.formio-component-datetime .input-group-text {
  @apply h-full;
}

.formio-component-day .row {
  @apply grid grid-cols-3;
}

.formio-component-day select[name="month"] {
  @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5;
}

.formio-component-day .formio-select-autocomplete-input {
  @apply p-0;
}

.formio-component .form-check-input:checked {
  @apply bg-primary-700 border-primary-700;
}

.formio-component-button {
  @apply sm:mt-4 px-0 flex justify-center flex-col items-center;
}

.formio-component-button .btn-primary {
  @apply text-kvarn-light-green select-none box-border rounded-lg inline-flex items-center justify-center whitespace-nowrap align-middle cursor-pointer disabled:cursor-not-allowed py-3 px-8 bg-kvarn-dark-green hover:bg-kvarn-button-hover hover:text-kvarn-black hover:drop-shadow focus:border-2 focus:border-primary-400;
}

.formio-component-button div[ref="buttonMessageContainer"].has-error {
  @apply hidden;
}

/* hiding the cross icon */
button.btn-primary:disabled::after {
  @apply hidden;
}

.formio-component .form-control.is-invalid {
  @apply border-red-600;
}

.formio-component .choices [hidden] {
  @apply hidden;
}

.formio-component-hidden {
  @apply hidden;
}

.form-text.text-muted {
  @apply text-gray-500 text-sm;
}
.heading-card-main {
  @apply text-gray-900 text-[1.25rem] font-bold;
}
.form-text.text-muted {
  @apply my-4;
}
.formio-component .list {
  @apply list-disc my-2;
}
.form-text.text-muted p {
  @apply my-4;
}
.fileSelector .sr-only {
  @apply hidden
}