@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("assets/fonts/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "TTFirst";
  src: url("assets/fonts/TTFirst.woff2") format("woff2");
  font-display: swap;
}
